<template>
  <v-dialog
    v-if="help != true"
    :width="this.size != null ? size : '900'"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card id="newPopup">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("inventorys.categorys") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="10" md="3" class="shorter">
            <v-text-field
              outlined
              dense
              v-model="filters.search"
              prepend-inner-icon="mdi-magnify"
              class="ml-auto filters search"
              :label="$t('search')"
              hide-details
              single-line
              @input="searchFilter"
            />
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="2" md="3" align="right" style="padding-top: 6px">
            <category-new @save="fetchCategorys"> </category-new>
          </v-col>
        </v-row>
        <v-data-table
          :footer-props="{
            'items-per-page-text': $vuetify.breakpoint.smAndDown
              ? ''
              : $t('items_per_page'),
          }"
          :headers="headers"
          :items="items"
          :search.sync="filters.search"
          :options.sync="options"
          :server-items-length="totalCategory"
          :loading="loading"
          :sort-by.sync="sortBy"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" large
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list>
                <category-edit
                  :id="item.id"
                  @updated="
                    fetchCategorys();
                    $emit('refresh');
                  "
                ></category-edit>
                <v-list-item class="braList" @click="del(item)">
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -3px !important"
                      >$delete</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>{{ $t("delete", { name: "" }) }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";

export default {
  name: "CategoryList",
  watch: {
    options: {
      handler() {
        this.fetchCategorys();
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (dialog) this.fetchCategorys();
      },
      deep: true,
    },
  },
  components: {
    CategoryNew: () => import("@/views/category/CategoryNew"),
    CategoryEdit: () => import("@/views/category/CategoryEdit"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("category.name"),
          align: "center",
          sortable: false,
          value: "category_name",
        },
        {
          text: this.$t("brand"),
          align: "center",
          sortable: false,
          value: "brand.brand_name",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: [],
      category: {},
      filters: {
        search: "",
        dates: "",
      },
      sortBy: "Nombre",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      totalCategory: null,
      dialog: false,
    };
  },
  mounted() {
    this.fetchCategorys();
  },
  methods: {
    ...mapActions("category", ["getCategorys", "deleteCategory"]),

    fetchCategorys() {
      this.loading = true;
      this.getCategorys({
        pagination: this.options,
        filters: this.filters,
      }).then((category) => {
        //console.log(category);
        this.items = category.data;
        this.totalCategory = category.total;
        this.loading = false;
      });
    },
    del(category) {
      this.$confirm(this.$t("delete_ask")).then(() => {
        this.deleteCategory(category.id).then((x) => {
          this.fetchCategorys();
          this.$alert(this.$t("delete_ok"));
        });
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchCategorys();
    }, 1000),
  },
};
</script>
<style lang="sass">
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important

.catList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.catList .v-icon, .catList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)

  @media (min-width: 800px)
    .shorter
      max-width: 170px
</style>
