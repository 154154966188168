var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.help != true)?_c('v-dialog',{attrs:{"width":this.size != null ? _vm.size : '900',"fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"newPopup"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.$t("inventorys.categorys"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"shorter",attrs:{"cols":"10","md":"3"}},[_c('v-text-field',{staticClass:"ml-auto filters search",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},on:{"input":_vm.searchFilter},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-spacer'),_c('v-col',{staticStyle:{"padding-top":"6px"},attrs:{"cols":"2","md":"3","align":"right"}},[_c('category-new',{on:{"save":_vm.fetchCategorys}})],1)],1),_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
            ? ''
            : _vm.$t('items_per_page'),
        },"headers":_vm.headers,"items":_vm.items,"search":_vm.filters.search,"options":_vm.options,"server-items-length":_vm.totalCategory,"loading":_vm.loading,"sort-by":_vm.sortBy},on:{"update:search":function($event){return _vm.$set(_vm.filters, "search", $event)},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('category-edit',{attrs:{"id":item.id},on:{"updated":function($event){_vm.fetchCategorys();
                  _vm.$emit('refresh');}}}),_c('v-list-item',{staticClass:"braList",on:{"click":function($event){return _vm.del(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-3px !important"},attrs:{"small":""}},[_vm._v("$delete")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("delete", { name: "" })))])])],1)],1)],1)]}}],null,false,966223514)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }